var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-body', [_c('div', {
    staticClass: "mt-4 mb-2 text-center"
  }, [_c('h4', [_vm._v("Related Products")]), _c('b-card-text', [_vm._v("People also search for this items")])], 1), _c('swiper', {
    staticClass: "swiper-responsive-breakpoints px-4 py-2",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.relatedProducts, function (product, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('b-link', [_c('div', {
      staticClass: "item-heading"
    }, [_c('h5', {
      staticClass: "text-truncate mb-0"
    }, [_vm._v(" " + _vm._s(product.name) + " ")]), _c('small', {
      staticClass: "text-body"
    }, [_vm._v("by " + _vm._s(product.brand))])]), _c('div', {
      staticClass: "img-container w-50 mx-auto py-75"
    }, [_c('b-img', {
      attrs: {
        "src": product.image,
        "fluid": ""
      }
    })], 1), _c('div', {
      staticClass: "item-meta"
    }, [_c('ul', {
      staticClass: "unstyled-list list-inline mb-25"
    }, _vm._l(5, function (star) {
      return _c('li', {
        key: star,
        staticClass: "ratings-list-item"
      }, [_c('feather-icon', {
        staticClass: "mr-25",
        class: [{
          'fill-current': star <= product.rating
        }, star <= product.rating ? 'text-warning' : 'text-muted'],
        attrs: {
          "icon": "StarIcon",
          "size": "18"
        }
      })], 1);
    }), 0), _c('p', {
      staticClass: "card-text text-primary mb-0"
    }, [_vm._v(" $" + _vm._s(product.price) + " ")])])])], 1);
  }), _c('div', {
    staticClass: "swiper-button-next",
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  }), _c('div', {
    staticClass: "swiper-button-prev",
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }