var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "app-ecommerce-details"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.product === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error fetching product data")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" No item found with this item slug. Check "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'apps-e-commerce-shop'
      }
    }
  }, [_vm._v(" Shop ")]), _vm._v(" for other items. ")], 1)]), _vm.product ? _c('b-row', {
    staticClass: "my-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('b-card-text', {
    staticClass: "item-company"
  }, [_c('b-link', {
    staticClass: "company-name"
  }, [_vm._v(" " + _vm._s(_vm.product.brand) + " ")])], 1), _c('h4', {
    staticClass: "mb-3"
  }, [_vm._v(_vm._s(_vm.product.name))]), _c('b-card-text', [_vm._v("Tentang buku ini ")]), _c('b-card-text', [_vm._v(_vm._s(_vm.product.description))])], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center mb-2 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-img', {
    staticClass: "product-img",
    attrs: {
      "src": _vm.product.image,
      "alt": "Image of ".concat(_vm.product.name),
      "fluid": ""
    }
  }), _vm._v(" Harga "), _c('br'), _c('h3', {
    staticClass: "text-primary mt-1"
  }, [_vm._v("Rp" + _vm._s(_vm.product.price))]), _c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _c('small', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Terbit 2023")]), _c('feather-icon', {
    staticClass: "mr-50 ml-2",
    attrs: {
      "icon": "BookOpenIcon"
    }
  }), _c('small', {
    staticClass: "font-weight-bold"
  }, [_vm._v("198 halaman")])], 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-cart btn-block",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleCartActionClick(_vm.product);
      }
    }
  }, [_c('span', [_vm._v("Beli")])])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }